import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { AbsenteeOptionModel } from '../absentee-option/absentee-option.model';
import { TimeOffBalanceModel } from '../time-off-balance/time-off-balance.model';

// Note: order of these enums is important as they also populate various selects throughout the app

export enum TimeOffAccrual {
  CONTRACT = 'CONTRACT',
  WORKED = 'WORKED',
  NONE = 'NONE',
}

export enum TimeOffDetermination {
  CONTRACT = 'CONTRACT',
  SCHEDULED = 'SCHEDULED',
  NONE = 'NONE',
}

export enum WaitHoursFrom {
  VACATION = 'VACATION',
  SALARY = 'SALARY',
  TIME_OFF_BALANCE = 'TIME_OFF_BALANCE',
}

export enum DayValueDetermination {
  CONTRACT = 'CONTRACT',
  GERMAN_13_WEEK = 'GERMAN_13_WEEK',
  NONE = 'NONE',
}

export interface AbsencePolicyModel {
  id?: string;
  name?: string;
  description?: string;
  contractTypeIds?: string[];
  timeOffAccrualSourceHours: TimeOffAccrual;
  waitHoursFrom: WaitHoursFrom;
  waitHoursFromTimeOffBalanceId: string | null;
  publicHolidayAbsenceTypeId: string | null;
  configuration?: AbsencePolicyConfigurationModel[];
}

export interface AbsencePolicyConfigurationModel {
  absenceTypeId: string;
  balanceIds: string[];
  timeOffDetermination: TimeOffDetermination;
  dayValueDetermination: DayValueDetermination;
}

export interface AbsencePolicyBatchFormRow {
  activated: boolean;
  policyId: string;
  policyName: string;
  configuration: AbsencePolicyConfigurationModel;
}

export interface AbsencePolicyConfigurationFullModel {
  absenceType: AbsenteeOptionModel;
  enabled: boolean;
  balances: TimeOffBalanceModel[];
}

export enum AbsencePolicyMaxTextLength {
  NAME = 160,
  DESCRIPTION = 1000,
}
